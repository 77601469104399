// stylelint-disable selector-no-qualifying-type

//
// Textual form controls
//

.form-control {
  display: block;
  width: 100%;
  padding: $input-padding-y $input-padding-x;
  font-size: $font-size-base;
  line-height: $input-line-height;
  color: $input-color;
  background-color: $input-bg;
  background-clip: padding-box;
  border: $input-border-width solid $input-border-color;

  // Note: This has no effect on <select>s in some browsers, due to the limited stylability of `<select>`s in CSS.
  @if $enable-rounded {
    // Manually use the if/else instead of the mixin to account for iOS override
    border-radius: $input-border-radius;
  } @else {
    // Otherwise undo the iOS default
    border-radius: 0;
  }

  @include box-shadow($input-box-shadow);
  @include transition($input-transition);

  // Unstyle the caret on `<select>`s in IE10+.
  &::-ms-expand {
    background-color: transparent;
    border: 0;
  }

  // Customize the `:focus` state to imitate native WebKit styles.
  @include form-control-focus();

  // Placeholder
  &::placeholder {
    color: $input-placeholder-color;
    // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
    opacity: 1;
  }

  // Disabled and read-only inputs
  //
  // HTML5 says that controls under a fieldset > legend:first-child won't be
  // disabled if the fieldset is disabled. Due to implementation difficulty, we
  // don't honor that edge case; we style them as disabled anyway.
  &:disabled,
  &[readonly] {
    background-color: $input-disabled-bg;
    // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
    opacity: 1;
  }
}

select.form-control {
  &:not([size]):not([multiple]) {
    height: $input-height;
  }

  &:focus::-ms-value {
    // Suppress the nested default white text on blue background highlight given to
    // the selected option text when the (still closed) <select> receives focus
    // in IE and (under certain conditions) Edge, as it looks bad and cannot be made to
    // match the appearance of the native widget.
    // See https://github.com/twbs/bootstrap/issues/19398.
    color: $input-color;
    background-color: $input-bg;
  }
}

// Make file inputs better match text inputs by forcing them to new lines.
.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

//
// Labels
//

// For use with horizontal and inline forms, when you need the label (or legend)
// text to align with the form controls.
.col-form-label {
  padding-top: calc(#{$input-padding-y} + #{$input-border-width});
  padding-bottom: calc(#{$input-padding-y} + #{$input-border-width});
  margin-bottom: 0; // Override the `<label>/<legend>` default
  font-size: inherit; // Override the `<legend>` default
  line-height: $input-line-height;
}

.col-form-label-lg {
  padding-top: calc(#{$input-padding-y-lg} + #{$input-border-width});
  padding-bottom: calc(#{$input-padding-y-lg} + #{$input-border-width});
  font-size: $font-size-lg;
  line-height: $input-line-height-lg;
}

.col-form-label-sm {
  padding-top: calc(#{$input-padding-y-sm} + #{$input-border-width});
  padding-bottom: calc(#{$input-padding-y-sm} + #{$input-border-width});
  font-size: $font-size-sm;
  line-height: $input-line-height-sm;
}

// Readonly controls as plain text
//
// Apply class to a readonly input to make it appear like regular plain
// text (without any border, background color, focus indicator)

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: $input-padding-y;
  padding-bottom: $input-padding-y;
  margin-bottom: 0; // match inputs if this class comes on inputs with default margins
  line-height: $input-line-height;
  background-color: transparent;
  border: solid transparent;
  border-width: $input-border-width 0;

  &.form-control-sm,
  &.form-control-lg {
    padding-right: 0;
    padding-left: 0;
  }
}

// Form control sizing
//
// Build on `.form-control` with modifier classes to decrease or increase the
// height and font-size of form controls.
//
// The `.form-group-* form-control` variations are sadly duplicated to avoid the
// issue documented in https://github.com/twbs/bootstrap/issues/15074.

.form-control-sm {
  padding: $input-padding-y-sm $input-padding-x-sm;
  font-size: $font-size-sm;
  line-height: $input-line-height-sm;
  @include border-radius($input-border-radius-sm);
}

select.form-control-sm {
  &:not([size]):not([multiple]) {
    height: $input-height-sm;
  }
}

.form-control-lg {
  padding: $input-padding-y-lg $input-padding-x-lg;
  font-size: $font-size-lg;
  line-height: $input-line-height-lg;
  @include border-radius($input-border-radius-lg);
}

select.form-control-lg {
  &:not([size]):not([multiple]) {
    height: $input-height-lg;
  }
}

// Form groups
//
// Designed to help with the organization and spacing of vertical forms. For
// horizontal forms, use the predefined grid classes.

.form-group {
  margin-bottom: $form-group-margin-bottom;
}

.form-text {
  display: block;
  margin-top: $form-text-margin-top;
}

// Form grid
//
// Special replacement for our grid system's `.row` for tighter form layouts.

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;

  > .col,
  > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px;
  }
}

// Checkboxes and radios
//
// Indent the labels to position radios/checkboxes as hanging controls.

.form-check {
  position: relative;
  display: block;
  padding-left: $form-check-input-gutter;
}

.form-check-input {
  position: absolute;
  margin-top: $form-check-input-margin-y;
  margin-left: -$form-check-input-gutter;

  &:disabled ~ .form-check-label {
    color: $text-muted;
  }
}

.form-check-label {
  margin-bottom: 0; // Override default `<label>` bottom margin
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0; // Override base .form-check
  margin-right: $form-check-inline-margin-x;

  // Undo .form-check-input defaults and add some `margin-right`.
  .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: $form-check-inline-input-margin-x;
    margin-left: 0;
  }
}

// Form validation
//
// Provide feedback to users when form field values are valid or invalid. Works
// primarily for client-side validation via scoped `:invalid` and `:valid`
// pseudo-classes but also includes `.is-invalid` and `.is-valid` classes for
// server side validation.

@include form-validation-state("valid", $form-feedback-valid-color);
@include form-validation-state("invalid", $form-feedback-invalid-color);

// Inline forms
//
// Make forms appear inline(-block) by adding the `.form-inline` class. Inline
// forms begin stacked on extra small (mobile) devices and then go inline when
// viewports reach <768px.
//
// Requires wrapping inputs and labels with `.form-group` for proper display of
// default HTML form controls and our custom form controls (e.g., input groups).

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; // Prevent shorter elements from growing to same height as others (e.g., small buttons growing to normal sized button height)

  // Because we use flex, the initial sizing of checkboxes is collapsed and
  // doesn't occupy the full-width (which is what we want for xs grid tier),
  // so we force that here.
  .form-check {
    width: 100%;
  }

  // Kick in the inline
  @include media-breakpoint-up(sm) {
    label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0;
    }

    // Inline-block all the things for "inline"
    .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0;
    }

    // Allow folks to *not* use `.form-group`
    .form-control {
      display: inline-block;
      width: auto; // Prevent labels from stacking above inputs in `.form-group`
      vertical-align: middle;
    }

    // Make static controls behave like regular ones
    .form-control-plaintext {
      display: inline-block;
    }

    .input-group {
      width: auto;
    }

    // Remove default margin on radios/checkboxes that were used for stacking, and
    // then undo the floating of radios and checkboxes to match.
    .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0;
    }
    .form-check-input {
      position: relative;
      margin-top: 0;
      margin-right: $form-check-input-margin-x;
      margin-left: 0;
    }

    .custom-control {
      align-items: center;
      justify-content: center;
    }
    .custom-control-label {
      margin-bottom: 0;
    }
    
  }
}
.row-full-width-clear-both{
    clear: both !important;
    flex: none !important;
    flex-wrap: nowrap !important;
    
}
.lawarow
{
    display: block !important;
    width: 100% !important;
}
.clearfix{
  display: block !important;
  clear: both !important;
  content: "" !important; 
  width: 100% !important;
}

.lawadivinline
{
    display: inline-block;
}

.form-full-width-lawa
{

	margin-right: 0px !important;
	margin-left: 0px !important;
}
.fullWidthLawaNoPadding
{
	padding-right: 0px !important;
	padding-left: 0px !important;
}

@media print
{    
    body {
    margin: 0 !important;
    color: #000 !important;
    background-color: #fff !important;
    visibility: visible !important;
    height: auto !important;
        
  }
    @page{
        size:auto !important;
    }
    .app-header
    {
        height: 0 !important;    
        min-height:0 !important;
    }
     .lawaPrintSpacing
    {
        margin: 1px !important;
    }
    .lawaPrintHeader,.jr-card 
    {
        padding: 5px !important;
        margin-bottom: 0px !important;
    }
    
    .printCSSSpacingLawa
    {
       padding: 8px !important; 
    }
   
     
}
 
.row-full-width
{
	width:100%;
}

.selectbox-straight-line
{
	margin-top: 16px !important;
}
.checkbox-straight-line
{
	margin-top: 13px !important;
}

.credit-images-lawa
{
	width: 140px;
	height: 100px;
}
.lawaTextRight
{
	float: right;
}
.lawaLinkDesign
{
	padding-top: 12px !important;
}
.lawaLinkLikeButton{
    /*padding-top: 10px !important;*/
	padding:10px 10px !important;
}
.LawaTextBoxLabel
{
	padding-top: 16px;
}
.LawaBookingExpansionBlocks
{
	width:100%;
	margin-left: 10px;
	margin-right: 10px;
	margin-top: 10px;
	
}
.formControl_row{
  width: 50%,
}
@media screen and (max-width:"768px") {
  .formControl_row{
    width: 100%,
  }
}
.LawaSmallTextBox
{
	width: 10% !important;
}
.LawaFieldHorizontalSpacing  
{
	padding-left: 5px !important;
}
.LawaCardBoxTopMargin
{
	margin-top: 10px !important;
}
.LawaTopButtonAlignment
{
	margin-top: 20px !important;
}
.LawaAlignRight
{
	text-align: right;
}
.hideblockLawa
{
	display: none !important;
}
.LawaFloatLeft
{
	float: left;
}
.LawaFloatRight
{
	float: right;
}
.whiteFontLawa,.whiteFontLawa:hover
{
	color: #ffffff !important;
	text-decoration: none;
}
.LawaTopHeaderMenu
{
	position: relative;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    padding: 2px 8px 2px 8px;
    border-radius: 6px;
    background-color: rgba(0, 0, 0, 0.15);
    cursor: pointer;
}
.iframeLoading
{
    background:url(/images/loading_react.gif) top no-repeat;
    background-position-y: 50px;
}
.cp-popoverlawa
{
	position: relative !important;
}
.notification-container
{
	top:64px !important;
}
.versionLawa
{
	color:#D1D6EF;
	padding-left: 5px;
  @media (max-width: 768px) {
    padding: 4px;
    position: absolute;
    left: 0;
    top: 0;
    font-size: 12px;
  }
}
.LawaSmallTextBoxPrice
{
	width:50px;
	margin-top: 8px !important;
	margin-bottom: 0px !important;
	margin-left: -12px !important;
	

  input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

}
.lawaFileUPloader
{
	padding-top: 10px;	
}
.lawaFileUpload
{
	float: left;
}
.lawaCenterButtonWithTopPadding
{
	text-align: center;
	padding-top: 10px;
}

.lawaCalendarSearchBlock
{
	margin-bottom: 10px;
}
.lawaTimeFace
{
	width:50%;
	color:#FF0000 !important;
	margin-top: 24px;
	height:40px;
	padding-right: 6px;
	font-weight: bold;
    /*margin-bottom: 8px;
	padding: 6px 0 7px;*/
}
.lawav1Booking {
  background: linear-gradient(to right, #f5d8d8 60%, white 40%);
  margin-right: 3px !important; }

 .lawav2Booking {
  background: linear-gradient(to right, #7bfd8e 60%, white 40%);
  margin-right: 3px !important; }

.lawav3Booking
{
  background: linear-gradient(to right, #cecece 60%, white 40%);
  margin-right: 3px !important; 
}

.lawaconfirmedBooking
{
    
}

.lawaPaddingTop
{
    padding-top: 5px;
}
.rc-time-picker-input
{
	height:40px !important;
	border:0px !important;
	border-bottom: 1px solid #e7e8e9 !important;
}
.rc-time-picker-panel-input-wrap
{
	padding:5px 11px !important;
}
.rc-time-picker-panel{
	z-index: 9100 !important;
}

.SelectedWeekExample {
    line-height: 1.5;
 }
.SelectedWeekExample .DayPicker-Month {
              border-collapse: separate;
            /*padding: -5px;*/
            }
            .SelectedWeekExample .DayPicker-WeekNumber {
              outline: none;
            }
            .SelectedWeekExample .DayPicker-Day {
              outline: none;
                
              padding: 7px;
              border: 1px solid transparent;
            }
            .SelectedWeekExample .DayPicker-Day--hoverRange {
              background-color: #EFEFEF !important;
              border-radius: 0 !important;
                
            }

            .SelectedWeekExample .DayPicker-Day--selectedRange {
              background-color: #8a96d7 !important;
              border-top-color: #4A90E2;
              border-bottom-color: #4A90E2;
              border-left-color: #8a96d7;
              border-right-color: #8a96d7;
              border-radius: 0 !important;
			  color:#FFF !important;
            }

            .SelectedWeekExample .DayPicker-Day--selectedRangeStart {
              background-color: #4A90E2 !important;
              border-left: 1px solid #4A90E2;
            }

            .SelectedWeekExample .DayPicker-Day--selectedRangeEnd {
              background-color: #4A90E2 !important;
              border-right: 1px solid #4A90E2;
            }

            .SelectedWeekExample .DayPicker-Day--selectedRange:not(.DayPicker-Day--outside).DayPicker-Day--selected,
            .SelectedWeekExample .DayPicker-Day--hoverRange:not(.DayPicker-Day--outside).DayPicker-Day--selected {
              border-radius: 0 !important;
              color: black !important;
            }
            .SelectedWeekExample .DayPicker-Day--hoverRange:hover {
              border-radius: 0 !important;
            }
.calenderdaterange select
{
  padding: 2px;
  margin-right: 5px !important;
}
.calenderbtn
{
    float: left !important;
    background-color: #FFF !important;
    min-height: 79px;
    padding: 17px 1px;
    border-bottom: solid 1px #e9ecef;
}

@media screen and (max-width: 1199px)
{
    .module-box-header
    {
        padding: 5px 22px 5px 4px !important;
    }
    .calenderbtn
    {
        float: left !important;
        background-color: #FFF !important;
        min-height: 58px;
        padding: 4px 1px;
        border-bottom: solid 1px #e9ecef;
    }

}
.calenderbtn-i{
	font-size: 30px;
	color:#4A90E2;
}
.calendarAlertDialog
{
    width: 20% !important;
}
.alertCenterText
{
    text-align: center !important;
}

.lawaTableCellMinPadding
{
    padding: 4px 15px 4px 15px !important;
}
.customer-menu {
    color: #000;
    text-decoration: none;
}

 

.customer-menu:hover {
    color: #000;
    text-decoration: none;
}
.lawaMiniTableRow
{
	height: 100% !important;
    padding: 5px 0px !important;
}

.customerNotesLawa
{
	background-color: #fffbce;
	margin-top: 5px;
	font-size: 14px;
	clear: both;
	white-space: initial !important; 
	padding: 10px 5px;
}

.LawaList li, .LawaList li div
{
	padding-left: 0px !important;
}
.softListLawa span
{
	color: #888888 !important;
}

.lawaSmallPaddingCell
{
 	padding: 4px 24px 4px 4px !important;
}
.smsMessageLawa textarea
{
	font-size: 14px !important;
}
.fetch-calendar {
    float: left;
    background: #3f51b5;
    margin-top: 20px;
    padding: 0 10px;
}
.fetch-calendar button{
    color: #fff !important;
}
.list-expense-line{
	border-top: 1px solid #eee !important;
    padding: 10px 0;
    margin-top: 20px;
}