/*Faqs Styles*/

.Collapsible {
  background-color: $white;
  margin-bottom: 15px;
}

//The content within the collaspable ChatUserList
.Collapsible__contentInner {
  padding: 20px;
  border: 1px solid $white;
  border-top: 0;

  p {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

//The link which when clicked opens the collapsable ChatUserList
.Collapsible__trigger {
  display: block;
  font-weight: 400;
  text-decoration: none;
  position: relative;
  padding: 18px 20px;
  @include border-radius(6px);
  background: theme-color("primary");
  color: $white;

  &:after {
    content: '\f2f9';
    font: {
      family: 'Material-Design-Iconic-Font';
      size: 20px;
    }
    position: absolute;
    right: 24px;
    top: 16px;
    display: block;
    transition: transform 300ms;
  }

  &.is-open {
    @include border-radius(6px 6px 0 0);
    &:after {
      transform: rotateZ(180deg);
    }
  }
}

//SideNav
.categories-list {
  margin-bottom: 0;
  & li {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  & li.active a {
    color: $app-primary;
  }
  & .badge {
    font-size: 9px;
  }
}

.articles-section {
  & .articles {
    margin-bottom: 26px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.article-image {
  display: block;
  margin-bottom: 12px;
  & > img {
    @include border-radius(6px);
    width: 100%;
  }
}

.article-description {
  & p {
    font-weight: 500;
    margin-bottom: 2px;
  }
}

.meta-wrapper {
  margin-bottom: 10px;
}

.meta-date,
.meta-comment {
  font-size: 12px;
  color: $light-gray;
  display: inline-block;
  & i {
    display: inline-block;
    margin-right: 6px;
  }
}

.meta-date-light {
  color: #acacac;
}

.meta-date {
  & + .meta-comment {
    margin-left: 10px;
  }
}

.tag_leads_completed {
  position: absolute;
  right: -5px; top: 75px;
  z-index: 1;
  overflow: hidden;
  width: 135px; height: 135px;
  text-align: right;
    span {
      font-size: 12px;
      font-weight: bold;
      color: #FFF;
      text-transform: uppercase;
      text-align: center;
      line-height: 25px;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      width: 170px;
      display: block;
      background: #79A70A;
      background: linear-gradient(#F70505 0%, #8F0808 100%);
      box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
      position: absolute;
      top: 40px; right: -33px;
      &::before{
        content: "";
        position: absolute; left: 0px; top: 100%;
        z-index: -1;
        border-left: 3px solid #8F0808;
        border-right: 3px solid transparent;
        border-bottom: 3px solid transparent;
        border-top: 3px solid #8F0808;
      }
      &::after{
        content: "";
        position: absolute; right: 0px; top: 100%;
        z-index: -1;
        border-left: 3px solid transparent;
        border-right: 3px solid #8F0808;
        border-bottom: 3px solid transparent;
        border-top: 3px solid #8F0808;            
      }
    }
  
}

.tag_leads_mobile {
  position: absolute;
  right: -5px; top: 75px;
  z-index: 1;
  overflow: hidden;
  width: 135px; height: 135px;
  text-align: right;
    span {
      font-size: 12px;
      font-weight: bold;
      color: #FFF;
      text-transform: uppercase;
      text-align: center;
      line-height: 25px;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      width: 115px;
      display: block;
      background: #79A70A;
      background: linear-gradient(#F70505 0%, #8F0808 100%);
      box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
      position: absolute;
      top: 19px; right: -29px;
      &::before{
        content: "";
        position: absolute; left: 0px; top: 100%;
        z-index: -1;
        border-left: 3px solid #8F0808;
        border-right: 3px solid transparent;
        border-bottom: 3px solid transparent;
        border-top: 3px solid #8F0808;
      }
      &::after{
        content: "";
        position: absolute; right: 0px; top: 100%;
        z-index: -1;
        border-left: 3px solid transparent;
        border-right: 3px solid #8F0808;
        border-bottom: 3px solid transparent;
        border-top: 3px solid #8F0808;            
      }
    }
  
}

.size-image-120 {
  width:120px;
  height:120px;
  line-height: 120px;
  margin-bottom: 25px;
  @media(max-width:768px){
    width:75px;
    height:75px;
    line-height: 75px;
    margin-bottom: 10px;
  }
}

.size-image-100 {
  width:100px;
  height:100px;
  line-height: 100px;
  margin-bottom: 25px;
  @media(max-width:768px){
    width:65px;
    height:65px;
    line-height: 65px;
    margin-bottom: 10px;
  }
}

.width-mobile{
  @media(max-width:500px){
    width:625px;
  }
}


