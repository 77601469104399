/*----------------Account settings CSS----------------------*/
.account-settings {
  width: 96%;
  margin: 2%;
  background: white;
  border-radius: 10px;
  border: 1px solid #eaeaea;
}

.account-settings .account-section-buttons {
  display: flex;
  justify-content: center;
  padding: 2%;
}

.account-settings .Edit-box {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 2%;
}

.GroupEdit-card {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
/*-------------------------------------------------------------*/

/*----------------Profile Header CSS----------------------*/
.jr-profile-content {
  position: relative;
  z-index: 0;
}

.subscribe_overlay {
  position: absolute;
  z-index: 10;
  background-color: #ece9eccc;
  border: 1px;
  border-radius: 3px;
  top: 20vh;
  width: 100%;
  height: 30vh;
  filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.3));
}

.grid-5-dashboard {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 1rem;
  margin:0 16px;
  @media(max-width:768px){
    grid-template-columns: repeat(2, 1fr);
  }
}

.grid-3-1{
  display: grid;
  grid-template-columns: 4fr 2fr;
  @media(max-width:768px){
    grid-template-columns: 1fr;
  }
}

.h-82{
  height: 82% !important;
}

.subscribe_overlay .please_subscribe {
  margin: 0 auto;
  position: absolute;
  top: 45%;
  left: 30%;
}
@media(max-width:768px){
  .subscribe_overlay {
    top: 24vh;
    width: 100%;
    height: 10vh;
  }
  .subscribe_overlay .please_subscribe {
    top: 20px;
    left: 20px;
    font-size: 17px;
  }
}
/*-------------------------------------------------------------*/
