.media {
  display: flex;
  align-items: flex-start;
  @media screen and (max-width: "768px") {
    justify-content: flex-end;
  }
}

.media-body {
  flex: 1;
}
